import 'dotenv/config';
import { getSessionCookieDomain } from './src/services/session/getSessionCookieDomain';

export const ENV = process.env.RAILWAY_ENVIRONMENT || 'development';
export const isProductionEnv = ENV === 'production';
export const isDevelopmentEnv = ENV === 'development';

export const DATABASE_URL = process.env.DATABASE_URL || '';
export const DATABASE_URL_SUFFIX = process.env.DATABASE_URL_SUFFIX || '';

export const CORS_ORIGIN = [
  'http://localhost:4000',
  'http://localhost:4020',
  /^https:\/\/(app|keystone|manifesto)-t2-web[a-z0-9-_]+\.up\.railway\.app$/,
  /^https:\/\/t2-(app|keystone|manifesto)\.up\.railway\.app$/,
  'https://app.t2.world',
  'https://t2.world',
  'https://manifesto-app.t2.world',
];

export const SESSION_COOKIE_DOMAIN =
  process.env.SESSION_COOKIE_DOMAIN || getSessionCookieDomain(process.env.RAILWAY_SERVICE_KEYSTONE_URL) || 'localhost';

export const CLOUDINARY_CONFIGS = {
  cloudName: process.env.CLOUDINARY_CLOUD_NAME || ' ',
  apiKey: process.env.CLOUDINARY_API_KEY || ' ',
  apiSecret: process.env.CLOUDINARY_API_SECRET || ' ',
  folder: process.env.RAILWAY_ENVIRONMENT || process.env.CLOUDINARY_API_FOLDER || ' ',
};

export const NEXT_PUBLIC_APP_URL =
  process.env.NEXT_PUBLIC_APP_URL ||
  (process.env.RAILWAY_SERVICE_APP_URL && `https://${process.env.RAILWAY_SERVICE_APP_URL}`) ||
  'http://localhost:4000';

export const { KLAVIYO_API_KEY, AWS_S3_DB_BACKUP_BUCKET, AWS_SECRET_ACCESS_KEY, AWS_ACCESS_KEY_ID, AWS_S3_REGION } =
  process.env;

export const TIME_POINTS_FOR_AUTHOR_RATION =
  (process.env.TIME_POINTS_FOR_AUTHOR_RATION && parseFloat(process.env.TIME_POINTS_FOR_AUTHOR_RATION)) || 1;

export const { REDIS_URL } = process.env;
export const REDIS_CACHE_EXPIRY_TIME_IN_SECONDS = process.env.REDIS_CACHE_EXPIRY_TIME_IN_SECONDS
  ? +process.env.REDIS_CACHE_EXPIRY_TIME_IN_SECONDS
  : 300;

export const API_KEY_HEADER = 'keystone-api-key';

export const { TURNSTILE_SECRET_KEY, TURNSTILE_SITE_KEY } = process.env;

export const { PRIVY_APP_ID, PRIVY_APP_SECRET } = process.env;

export const { SENTRY_DSN } = process.env;

// eslint-disable-next-line prefer-destructuring
export const IRYS_NODE_URL = process.env.IRYS_NODE_URL;
// eslint-disable-next-line prefer-destructuring
export const IRYS_PRIVATE_KEY = process.env.IRYS_PRIVATE_KEY;
// eslint-disable-next-line prefer-destructuring
export const IRYS_RPC_URL = process.env.IRYS_RPC_URL;

export const KEYSTONE_URL =
  process.env.KEYSTONE_URL ||
  (process.env.RAILWAY_SERVICE_KEYSTONE_URL && `https://${process.env.RAILWAY_SERVICE_KEYSTONE_URL}`) ||
  'http://localhost:3000';

export const LENS_ENV = process.env.LENS_ENVIRONMENT;
export const isLensProductionEnv = LENS_ENV === 'production';
export const LENS_CAMPAIGN_MIRROR_POST_ID =
  process.env.LENS_CAMPAIGN_POST_ID || (isLensProductionEnv ? '0xf3-0x01-DA-9d3535b6' : '0x61-0x01-DA-fa6089d5');

export const POSTMARK_API_TOKEN = process.env.POSTMARK_API_TOKEN || '';
export const POSTMARK_ENABLED = process.env.POSTMARK_ENABLED === 'true';

export const shouldSendEmails = POSTMARK_ENABLED && !!POSTMARK_API_TOKEN;

export const shouldGatherMetrics = isProductionEnv;
