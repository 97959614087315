import { ConnectedWallet, useWallets } from '@privy-io/react-auth';
import { useEffect, useRef, useState } from 'react';
import { usePrivy } from 't2-keystone/packages/privyAuthentication/src/hooks/usePrivy';
import { WalletConnectionError } from './constants';

export const useConnectedWallet = () => {
  const [isConnectionInProgress, setIsConnectionInProgress] = useState<boolean>(false);
  const resolver = useRef<(value: ConnectedWallet) => void>(null);
  const rejector = useRef<(error?: any) => void>(null);
  const { wallets } = useWallets();
  const { connectWallet, user, isModalOpen } = usePrivy();

  const userWalletAddress = user?.wallet?.address;
  const connectedWallet = userWalletAddress
    ? wallets.find((wallet) => wallet.address === userWalletAddress)
    : undefined;

  const connectWalletAsync = () => {
    connectWallet();
    setIsConnectionInProgress(true);

    // @ts-ignore
    return new Promise<ConnectedWallet>((resolve, reject) => {
      // @ts-ignore
      resolver.current = resolve;
      // @ts-ignore
      rejector.current = reject;
    });
  };

  const resetPromise = () => {
    // @ts-ignore
    resolver.current = null;
    // @ts-ignore
    rejector.current = null;
  };

  useEffect(() => {
    const hasSuccessfullyConnectedWallet = isConnectionInProgress && !!connectedWallet;
    if (hasSuccessfullyConnectedWallet) {
      resolver.current?.(connectedWallet);
    }

    const hasAnyWalletConnected = wallets.length > 0;
    const hasDeclined = isConnectionInProgress && !isModalOpen && !connectedWallet && !hasAnyWalletConnected;
    if (hasDeclined) {
      rejector.current?.(new Error(WalletConnectionError.Canceled));
    }

    const hasWalletMismatch = isConnectionInProgress && !isModalOpen && hasAnyWalletConnected && !connectedWallet;
    if (hasWalletMismatch) {
      rejector.current?.(new Error(WalletConnectionError.WrongAccount));
    }

    if (hasSuccessfullyConnectedWallet || hasDeclined || hasWalletMismatch) {
      setIsConnectionInProgress(false);
      resetPromise();
    }
  }, [isModalOpen, isConnectionInProgress]);

  return { connectWalletAsync, connectedWallet };
};
