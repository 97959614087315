import { ConnectedWallet } from '@privy-io/react-auth';
import { createContext, useContext, useMemo } from 'react';
import { useConnectedWallet } from './useConnectedWallet';

type TWalletConnectionContextType = {
  connectedWallet: ConnectedWallet | undefined;
  connectWalletAsync: (() => Promise<ConnectedWallet>) | undefined;
};

const INITIAL_STATE: TWalletConnectionContextType = {
  connectedWallet: undefined,
  connectWalletAsync: undefined,
};

export const WalletConnectionContext = createContext<TWalletConnectionContextType>(INITIAL_STATE);

export const WalletConnectionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { connectWalletAsync, connectedWallet } = useConnectedWallet();

  const value = useMemo(() => ({ connectedWallet, connectWalletAsync }), [connectedWallet]);

  return <WalletConnectionContext.Provider value={value}>{children}</WalletConnectionContext.Provider>;
};

export const useWalletConnection = () => useContext(WalletConnectionContext);
