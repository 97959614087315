import cx from 'classnames';
import { ReactNode } from 'react';
import { CloseButton } from '../CloseButton/CloseButton';
import ModalHeader from './ModalHeader';

type CloseButtonProps = {
  className: string;
};

export type TModalProps = {
  children: ReactNode;
  isOpen?: boolean;
  onClose: () => void;
  containerClassName?: string;
  className?: string;
  title?: string;
  subtitle?: string;
  footer?: ReactNode;
  closeButtonProps?: CloseButtonProps;
};

export const Modal = ({
  children,
  className,
  isOpen,
  onClose,
  containerClassName,
  title,
  subtitle,
  footer,
  closeButtonProps,
}: TModalProps) => (
  <div
    id="defaultModal"
    className={cx(
      'fixed top-0 left-0 right-0 bottom-0 z-50 overflow-hidden w-full max-h-screen bg-black-700 bg-opacity-50 sm:py-7',
      { hidden: !isOpen, [className!]: !!className },
    )}
    onClick={onClose}
  >
    <div
      className={cx(
        'relative flex flex-col sm:max-w-[520px] py-6 px-10 bg-white-900 sm:rounded-4xl h-full sm:h-auto max-h-full sm:max-h-[550px] top-[50%] sm:top-[10vh] left-[50%] translate-x-[-50%] translate-y-[-50%] sm:translate-y-0',
        containerClassName,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={cx('absolute right-6 top-6 z-50', closeButtonProps?.className)}>
        <CloseButton onClick={onClose} />
      </div>
      {(title || subtitle) && <ModalHeader title={title} subtitle={subtitle} />}
      <div className="overflow-y-auto">{children}</div>
      {footer && <div className="sticky sm:px-10 pt-2 w-full text-center">{footer}</div>}
    </div>
  </div>
);
