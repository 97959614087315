import { createConfig } from '@privy-io/wagmi';
import { http } from 'wagmi';
import { polygon, polygonAmoy } from 'wagmi/chains';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient();

export const wagmiConfig = createConfig({
  chains: [polygonAmoy, polygon],
  transports: {
    [polygonAmoy.id]: http(),
    [polygon.id]: http(),
  },
});
