import { CSSProperties, ElementType, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';

export interface TypoProps extends HTMLAttributes<HTMLHeadingElement> {
  text?: React.ReactNode | string;
  className?: string;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'p1'
    | 'p2'
    | 'label'
    | 'small-p'
    | 'article-p'
    | 'article-h2'
    | 'article-h3'
    | 'article-h4';
  tag?: ElementType;
  style?: CSSProperties;
  children?: ReactNode;
  title?: string;
}

const variantClassNameMap = {
  h1: 'font-primary font-normal text-h1-m md:text-h1 not-italic',
  h2: 'font-primary font-normal text-h2-m md:text-h2 not-italic',
  h3: 'font-primary font-normal text-h3-m md:text-h3 not-italic',
  h4: 'font-primary font-normal text-h4-m md:text-h4 not-italic',
  p1: 'font-secondary font-normal text-p4 md:text-p1 not-italic',
  p2: 'font-secondary font-normal text-p2-m md:text-p2 not-italic',
  label: 'font-secondary font-semibold text-label md:text-label-m not-italic',
  'small-p': 'font-secondary text-xs md:text-base',
  'article-p': 'article__paragraph',
  'article-h2': 'article__h2',
  'article-h3': 'article__h3',
  'article-h4': 'article__h4',
};

const variantTagMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  p1: 'p',
  p2: 'p',
  label: 'label',
  'small-p': 'p',
  'article-p': 'p',
  'article-h2': 'h2',
  'article-h3': 'h3',
  'article-h4': 'h4',
};

const Typo: React.FC<TypoProps> = ({ text, className = '', variant, tag, style, children, title, ...props }) => {
  const Tag = tag || variantTagMap[variant!] || 'span';

  return (
    <Tag
      className={cx(variantClassNameMap[variant!], {
        [className]: className,
      })}
      style={style}
      title={title}
      {...props}
    >
      {children || text}
    </Tag>
  );
};

export default Typo;
